import React from "react";
import { connect } from "react-redux";
import { sampleFunction } from "../actions/user/action";

const TestRedux = ({ sampleFunction, darkMode }) => {
  return (
    <div style={darkMode ? { backgroundColor: "black", color: "white" } : null}>
      <button onClick={() => sampleFunction(darkMode)}>Darkmode toggle</button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  darkMode: state.data.isDarkMode,
});

export default connect(mapStateToProps, { sampleFunction })(TestRedux);
